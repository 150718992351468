var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {}, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "color": "#556ee6",
      "loader": "dots",
      "can-cancel": false,
      "is-full-page": false
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _vm.showForm ? _c('section', {
    staticClass: "flex h-full flex-col overflow-hidden",
    staticStyle: {
      "min-height": "800px"
    }
  }, [_c('div', {
    staticClass: "flex-1  py-6"
  }, [_vm._m(0), _c('form', {
    staticClass: "form mt-5 px-2",
    attrs: {
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitRegister.apply(null, arguments);
      }
    }
  }, [_vm.step === 1 ? _c('div', [_c('div', {
    staticClass: "flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.state.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300",
    attrs: {
      "id": "state",
      "tag-placeholder": "Estado *",
      "placeholder": "Estado  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": _vm.states,
      "multiple": false
    },
    on: {
      "select": _vm.setState
    },
    model: {
      value: _vm.form.state,
      callback: function ($$v) {
        _vm.$set(_vm.form, "state", $$v);
      },
      expression: "form.state"
    }
  })], 1), _c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.city.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300",
    attrs: {
      "id": "city",
      "tag-placeholder": "Cidade *",
      "placeholder": "Cidade  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "nome",
      "track-by": "nome",
      "options": _vm.cities,
      "multiple": false
    },
    on: {
      "select": _vm.getSchools
    },
    model: {
      value: _vm.form.city,
      callback: function ($$v) {
        _vm.$set(_vm.form, "city", $$v);
      },
      expression: "form.city"
    }
  })], 1)]), _c('div', {
    staticClass: "block md:flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300",
    attrs: {
      "id": "school",
      "tag-placeholder": "Escola *",
      "placeholder": "Escola  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "noOptions": "Nenhuma opção disponível",
      "deselectLabel": "Desmarcar",
      "label": "name",
      "track-by": "name",
      "options": _vm.schools,
      "multiple": false
    },
    on: {
      "select": _vm.setSchool
    },
    model: {
      value: _vm.form.school,
      callback: function ($$v) {
        _vm.$set(_vm.form, "school", $$v);
      },
      expression: "form.school"
    }
  })], 1)]), _vm.form.school ? _c('div', {
    staticClass: "block flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('h1', {
    staticClass: "text-616161 text-sm font-semibold"
  }, [_vm._v("SGE: " + _vm._s(_vm.form.sge))])]), _c('div', {
    staticClass: "relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('h1', {
    staticClass: "text-616161 text-sm font-semibold"
  }, [_vm._v("ID ARCO: " + _vm._s(_vm.form.idarco))])]), _c('div', {
    staticClass: "relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('h1', {
    staticClass: "text-616161 text-sm font-semibold"
  }, [_vm._v("Convênio: " + _vm._s(_vm.form.convenio))])])]) : _vm._e(), _c('div', {
    staticClass: "text-xs text-red-600 my-4"
  }, [_vm._v("*Vagas limitadas: apenas 1 gestor por escola poderá participar.")]), _c('div', {
    staticClass: "next-form py-3 flex gap-2"
  }, [_c('button', {
    staticClass: "flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-303dc6 rounded-lg focus:shadow-outline",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.nextStep();
      }
    }
  }, [_c('span', {
    staticClass: "flex justify-center mr-2"
  }, [_vm._v("Prosseguir")]), _c('svg', {
    staticClass: "feather feather-arrow-right",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('line', {
    attrs: {
      "x1": "5",
      "y1": "12",
      "x2": "19",
      "y2": "12"
    }
  }), _c('polyline', {
    attrs: {
      "points": "12 5 19 12 12 19"
    }
  })])])])]) : _vm._e(), _vm.step === 2 ? _c('div', [_c('div', {
    staticClass: "block md:flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "stroke": "currentColor",
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 20 20",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "height": "20px",
      "width": "20px",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
    }
  }), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "7",
      "r": "4"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.name.$error
    },
    attrs: {
      "type": "text",
      "id": "name",
      "placeholder": "Nome completo *"
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "relative my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "stroke": "currentColor",
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 20 20",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "height": "20px",
      "width": "20px",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"
    }
  }), _c('circle', {
    attrs: {
      "cx": "12",
      "cy": "7",
      "r": "4"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.badge_name,
      expression: "form.badge_name"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.badge_name.$error
    },
    attrs: {
      "type": "text",
      "id": "badge_name",
      "placeholder": "Nome para Crachá *"
    },
    domProps: {
      "value": _vm.form.badge_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "badge_name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "block md:flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "mb-4 md:mb-0 relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "stroke": "currentColor",
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
    }
  }), _c('polyline', {
    attrs: {
      "points": "22,6 12,13 2,6"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.email.$error
    },
    attrs: {
      "type": "text",
      "id": "email",
      "placeholder": "E-mail *"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "stroke": "currentColor",
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.phone,
      expression: "form.phone"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: _vm.mask,
      expression: "mask"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.phone.$error
    },
    attrs: {
      "type": "text",
      "id": "phone",
      "placeholder": "Telefone com DDD *"
    },
    domProps: {
      "value": _vm.form.phone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "phone", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "block md:flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3 10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H13C16.7712 2 18.6569 2 19.8284 3.17157C21 4.34315 21 6.22876 21 10V14C21 17.7712 21 19.6569 19.8284 20.8284C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8284C3 19.6569 3 17.7712 3 14V10Z",
      "stroke": "#1C274C",
      "stroke-width": "1.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M8 10H16",
      "stroke": "#1C274C",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M8 14H13",
      "stroke": "#1C274C",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.cpf,
      expression: "form.cpf"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: '###.###.###-##',
      expression: "'###.###.###-##'"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.cpf.$error || _vm.errorCPF
    },
    attrs: {
      "type": "text",
      "id": "cpf",
      "placeholder": "CPF *"
    },
    domProps: {
      "value": _vm.form.cpf
    },
    on: {
      "change": function ($event) {
        return _vm.validCPF();
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "cpf", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "relative  my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500",
    attrs: {
      "fill": "none",
      "stroke-width": "2",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M3 10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H13C16.7712 2 18.6569 2 19.8284 3.17157C21 4.34315 21 6.22876 21 10V14C21 17.7712 21 19.6569 19.8284 20.8284C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8284C3 19.6569 3 17.7712 3 14V10Z",
      "stroke": "#1C274C",
      "stroke-width": "1.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M8 10H16",
      "stroke": "#1C274C",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M8 14H13",
      "stroke": "#1C274C",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }
  })])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.rg,
      expression: "form.rg"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.rg.$error
    },
    attrs: {
      "type": "text",
      "id": "rg",
      "placeholder": "RG *"
    },
    domProps: {
      "value": _vm.form.rg
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "rg", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.birth_date,
      expression: "form.birth_date"
    }, {
      name: "mask",
      rawName: "v-mask",
      value: _vm.maskDate,
      expression: "maskDate"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.birth_date.$error || _vm.validateDate.isInvalid
    },
    attrs: {
      "type": "text",
      "id": "birth_date",
      "placeholder": "Data de Nascimento *"
    },
    domProps: {
      "value": _vm.form.birth_date
    },
    on: {
      "blur": function () {
        return _vm.validateDate18Age();
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "birth_date", $event.target.value);
      }
    }
  }), _vm.isInvalidDate ? _c('span', {
    staticClass: "text-red-500 text-sm"
  }, [_vm._v("Você deve ter 18 anos ou mais.")]) : _vm._e()]), _c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.gender.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"
    }
  })])]), _c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Gênero *",
      "placeholder": "Gênero  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": [{
        label: 'Masculino',
        value: 'Masculino'
      }, {
        label: 'Feminino',
        value: 'Feminino'
      }],
      "multiple": false
    },
    model: {
      value: _vm.form.gender,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  })], 1)]), _c('div', {
    staticClass: "flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative mt-4",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.role.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Cargo *",
      "placeholder": "Cargo *",
      "select-label": "Selecionar",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "value",
      "options": _vm.roles,
      "multiple": false
    },
    model: {
      value: _vm.form.role,
      callback: function ($$v) {
        _vm.$set(_vm.form, "role", $$v);
      },
      expression: "form.role"
    }
  })], 1)]), _c('div', {
    staticClass: "flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative my-4",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.pcd.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Necessidades especiais ? *",
      "placeholder": "Necessidades especiais?  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": _vm.pcds,
      "multiple": false
    },
    model: {
      value: _vm.form.pcd,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pcd", $$v);
      },
      expression: "form.pcd"
    }
  })], 1), _c('div', {
    staticClass: "relative my-4",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.restriction_food.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Restrição alimentar ? *",
      "placeholder": "Restrição alimentar ?  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": _vm.restriction_foods,
      "multiple": false
    },
    model: {
      value: _vm.form.restriction_food,
      callback: function ($$v) {
        _vm.$set(_vm.form, "restriction_food", $$v);
      },
      expression: "form.restriction_food"
    }
  })], 1)]), _vm._m(1), _c('div', {
    staticClass: "py-4 mt-4 bg-white border-dashed border-2 border-gray-300 rounded-lg cursor-pointer",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.documentPhoto.$error
    },
    on: {
      "click": _vm.showFile
    }
  }, [!_vm.documentPhoto ? _c('div', {
    staticClass: "px-2 py-4 flex justify-center"
  }, [_c('svg', {
    attrs: {
      "width": "48",
      "height": "48",
      "stroke-width": "1.5",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V13",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M3 16L10 13L15.5 15.5",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M16 19H19M22 19H19M19 19V16M19 19V22",
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })])]) : _vm._e(), _vm.documentPhoto ? _c('p', {
    staticClass: "text-center text-xs font-medium mt-2 text-green-600"
  }, [_vm._v(_vm._s(_vm.documentPhoto.name))]) : _vm._e(), !_vm.documentPhoto ? _c('p', {
    staticClass: "text-center text-xs font-medium mt-2"
  }, [_vm._v("Clique aqui e anexe o seu documento com foto")]) : _vm._e(), !_vm.documentPhoto ? _c('p', {
    staticClass: "text-center text-xs font-normal mt-1"
  }, [_vm._v("PDF, JPG ou PNG")]) : _vm._e(), _c('input', {
    ref: "aereo",
    staticClass: "hidden",
    attrs: {
      "type": "file",
      "name": "aereo",
      "accept": "*/*"
    },
    on: {
      "change": _vm.setImage
    }
  })]), _c('div', {
    staticClass: "next-form py-3 flex gap-4 mt-10"
  }, [_c('button', {
    staticClass: "flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-gray-600 rounded-lg focus:shadow-outline",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.prevStep();
      }
    }
  }, [_c('svg', {
    staticClass: "feather feather-arrow-left",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('line', {
    attrs: {
      "x1": "19",
      "y1": "12",
      "x2": "5",
      "y2": "12"
    }
  }), _c('polyline', {
    attrs: {
      "points": "12 19 5 12 12 5"
    }
  })]), _c('span', {
    staticClass: "flex justify-center ml-2"
  }, [_vm._v("Voltar")])]), _c('button', {
    staticClass: "flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-303dc6 rounded-lg focus:shadow-outline",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.nextStep();
      }
    }
  }, [_c('span', {
    staticClass: "flex justify-center mr-2"
  }, [_vm._v("Prosseguir")]), _c('svg', {
    staticClass: "feather feather-arrow-right",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('line', {
    attrs: {
      "x1": "5",
      "y1": "12",
      "x2": "19",
      "y2": "12"
    }
  }), _c('polyline', {
    attrs: {
      "points": "12 5 19 12 12 19"
    }
  })])])])]) : _vm._e(), _vm.step === 3 ? _c('div', [_c('div', {
    staticClass: "block md:flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.departure_date.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Data Ida *",
      "placeholder": "Data Ida  *",
      "select-label": "Selecione",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": [{
        label: '26 de março de 2025',
        value: '26 de março de 2025'
      }],
      "multiple": false
    },
    model: {
      value: _vm.form.departure_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "departure_date", $$v);
      },
      expression: "form.departure_date"
    }
  })], 1), _c('div', {
    staticClass: "relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.return_date.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Data Volta *",
      "placeholder": "Data Volta  *",
      "select-label": "Selecione",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": [{
        label: '28 de março de 2025',
        value: '28 de março de 2025'
      }, {
        label: '29 de março de 2025',
        value: '29 de março de 2025'
      }, {
        label: '30 de março de 2025',
        value: '30 de março de 2025'
      }],
      "multiple": false
    },
    model: {
      value: _vm.form.return_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "return_date", $$v);
      },
      expression: "form.return_date"
    }
  })], 1)]), _c('div', {
    staticClass: "text-xs text-red-600 my-4"
  }, [_vm._v("*Caso o retorno escolhido seja no sábado ou domingo, os custos extras de hospedagem serão de responsabilidade do participante")]), _c('div', {
    staticClass: "block md:flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "mb-4 md:mb-0 relative",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.type_transport.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Meio de transporte ? *",
      "placeholder": "Meio de transporte?  *",
      "select-label": "Selecione uma opção",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": [{
        label: 'Aéreo',
        value: 'Aéreo'
      }, {
        label: 'Rodoviária',
        value: 'Rodoviária'
      }, {
        label: 'Carro Particular',
        value: 'Carro Particular'
      }],
      "multiple": false
    },
    model: {
      value: _vm.form.type_transport,
      callback: function ($$v) {
        _vm.$set(_vm.form, "type_transport", $$v);
      },
      expression: "form.type_transport"
    }
  })], 1)]), _c('div', {
    staticClass: "flex flex-row gap-2"
  }, [_vm.form.type_transport && _vm.form.type_transport.label === 'Rodoviária' ? _c('div', {
    staticClass: "relative my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.busstation_origin,
      expression: "form.busstation_origin"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    class: {
      'border-red-500': _vm.submitted && _vm.$v.form.busstation_origin.$error
    },
    attrs: {
      "type": "text",
      "id": "busstation_origin",
      "placeholder": "Rodoviária de origem ?*"
    },
    domProps: {
      "value": _vm.form.busstation_origin
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "busstation_origin", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.form.type_transport && _vm.form.type_transport.label === 'Aéreo' ? _c('div', {
    staticClass: "relative my-4",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.airport_origin.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Aeroporto de Origem ? *",
      "placeholder": "Aeroporto de Origem?  *",
      "select-label": "Selecionar",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "value",
      "options": _vm.airports,
      "multiple": false
    },
    model: {
      value: _vm.form.airport_origin,
      callback: function ($$v) {
        _vm.$set(_vm.form, "airport_origin", $$v);
      },
      expression: "form.airport_origin"
    }
  })], 1) : _vm._e()]), _vm.form.type_transport && _vm.form.type_transport.label === 'Aéreo' ? _c('div', {
    staticClass: "text-xs text-red-600 mb-4"
  }, [_vm._v("*Ao escolher o aeroporto de origem e data de retorno, não serão permitidas alterações futuras.")]) : _vm._e(), _c('div', {
    staticClass: "flex flex-row gap-2"
  }, [_c('div', {
    staticClass: "relative  my-4",
    class: {
      'invalid-select': _vm.submitted && _vm.$v.form.room_type.$error
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
  }, [_c('svg', {
    staticClass: "w-5 h-5 text-gray-500 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"
    }
  })])]), _c('multiselect', {
    staticClass: "text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "tag-placeholder": "Tipo de acomodação *",
      "placeholder": "Tipo de acomodação  *",
      "select-label": "Selecionar",
      "selectedLabel": "Selecionado",
      "deselectLabel": "Desmarcar",
      "label": "label",
      "track-by": "label",
      "options": [{
        label: 'Duplo',
        value: 'double'
      }, {
        label: 'Tripla',
        value: 'triple'
      }],
      "multiple": false
    },
    model: {
      value: _vm.form.room_type,
      callback: function ($$v) {
        _vm.$set(_vm.form, "room_type", $$v);
      },
      expression: "form.room_type"
    }
  })], 1), _c('div', {
    staticClass: "relative my-4",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name_companion,
      expression: "form.name_companion"
    }],
    staticClass: "bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200",
    attrs: {
      "type": "text",
      "id": "name_companion",
      "placeholder": "Nome do acompanhante "
    },
    domProps: {
      "value": _vm.form.name_companion
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "name_companion", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "text-xs text-red-600 my-2"
  }, [_vm._v("*O acompanhante também precisa realizar sua inscrição.")]), _c('div', {
    staticClass: "text-xs text-red-600 my-2"
  }, [_vm._v("*No momento não há quartos individuais disponíveis no evento. Para dúvidas acione seu consultor pedagógico")]), _c('div', {
    staticClass: "flex items-start mb-4 mt-6"
  }, [_c('div', {
    staticClass: "flex items-center h-5"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.authorizes_data_usage,
      expression: "form.authorizes_data_usage"
    }],
    key: "",
    staticClass: "w-5 h-5 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800",
    attrs: {
      "id": "authorizes_data_usage",
      "type": "checkbox",
      "required": ""
    },
    domProps: {
      "checked": Array.isArray(_vm.form.authorizes_data_usage) ? _vm._i(_vm.form.authorizes_data_usage, null) > -1 : _vm.form.authorizes_data_usage
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.authorizes_data_usage,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "authorizes_data_usage", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "authorizes_data_usage", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "authorizes_data_usage", $$c);
        }
      }
    }
  })]), _vm._m(2)]), _c('div', {
    staticClass: "next-form py-3 flex gap-4"
  }, [_c('button', {
    staticClass: "flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-gray-600 rounded-lg focus:shadow-outline",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.prevStep();
      }
    }
  }, [_c('svg', {
    staticClass: "feather feather-arrow-left",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke": "currentColor",
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }, [_c('line', {
    attrs: {
      "x1": "19",
      "y1": "12",
      "x2": "5",
      "y2": "12"
    }
  }), _c('polyline', {
    attrs: {
      "points": "12 19 5 12 12 5"
    }
  })]), _c('span', {
    staticClass: "flex justify-center ml-2"
  }, [_vm._v("Voltar")])]), _vm._m(3)])]) : _vm._e()])])]) : _vm._e(), _vm.showThanks ? _c('section', {
    staticClass: "flex justify-center"
  }, [_c('div', {
    staticClass: "w-full sm:w-full lg:w-full xl:w-full pt-10"
  }, [_c('div', {
    staticClass: "px-2 pt-2"
  }, [_vm._m(4), _c('div', {
    staticClass: "tittle mb-2 px-4 text-center"
  }, [_c('h1', {
    staticClass: "text-3259ce text-md font-bold mb-4"
  }, [_vm._v(_vm._s(_vm.participant.name) + ",")]), _vm._m(5)]), _vm._m(6)])])]) : _vm._e()], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "subtitle-register px-2"
  }, [_c('h1', {
    staticClass: "text-sm font-medium text-gray-600"
  }, [_vm._v("Preencha os campos abaixo para inscrever-se no evento:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-4"
  }, [_c('h1', {
    staticClass: "text-sm font-bold text-gray-600"
  }, [_vm._v("1. Faça o upload de um documento com foto.")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('label', {
    staticClass: "ml-2 text-sm font-medium text-gray-900",
    attrs: {
      "for": "authorizes_data_usage"
    }
  }, [_vm._v("Declaro que li e concordo com a "), _c('a', {
    staticClass: "text-blue-600 hover:underline dark:text-blue-500",
    attrs: {
      "href": "https://www.sistemapositivo.com.br/politica-de-privacidade/",
      "target": "_blank"
    }
  }, [_vm._v(" Política de privacidade ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-303dc6 rounded-lg focus:shadow-outline",
    attrs: {
      "type": "submit"
    }
  }, [_c('span', {
    staticClass: "flex justify-center mr-2"
  }, [_vm._v("Finalizar Cadastro")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex justify-center mb-4"
  }, [_c('img', {
    staticClass: "max-h-44 max-w-60 rounded-lg",
    attrs: {
      "src": require("../assets/sucess.png")
    }
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "text-616161 text-sm font-semibold"
  }, [_vm._v("Obrigado pelo seu interesse em construir, junto com a gente, "), _c('br'), _vm._v("um futuro sustentável e solidário!")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-2 mt-12 bg-white border-2 border-gray-200 rounded-lg"
  }, [_c('div', {
    staticClass: "mb-2 text-center px-4"
  }, [_c('h1', {
    staticClass: "text-616161 text-sm font-bold mb-4"
  }, [_vm._v("I M P O R T A N T E")]), _c('h1', {
    staticClass: "text-616161 text-sm font-normal"
  }, [_vm._v(" Sua inscrição foi recebida e passará por uma etapa de validação. "), _c('br'), _c('br'), _vm._v(" Assim que concluída, enviaremos a confirmação da sua inscrição e todos os detalhes da sua participação diretamente para o e-mail informado. "), _c('br'), _c('br'), _vm._v(" Portanto, fique atento(a) a sua caixa de entrada. ")])])]);
}]

export { render, staticRenderFns }