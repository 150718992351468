<template>
  <main class="">
    <loading
      :active.sync="loading"
      color="#556ee6"
      loader="dots"
      :can-cancel="false"
      :is-full-page="false">
    </loading>
    <section class="flex h-full flex-col overflow-hidden" style="min-height: 800px" v-if="showForm">
      <div class="flex-1  py-6">
        <div class="subtitle-register px-2">
          <h1 class="text-sm font-medium text-gray-600">Preencha os campos abaixo para inscrever-se no evento:</h1>
        </div>
        <form @submit.prevent="submitRegister" class="form mt-5 px-2" autocomplete="off">
          <div v-if="step === 1">
            <div class="flex flex-row gap-2">
              <div class="relative" :class="{ 'invalid-select': submitted && $v.form.state.$error }" style="width: 100%">
                <multiselect
                  id="state"
                  tag-placeholder="Estado *"
                  placeholder="Estado  *"
                  v-model="form.state"
                  select-label="Selecione uma opção"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  label="label"
                  track-by="label"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300"
                  :options="states"
                  @select="setState"
                  :multiple="false">
                </multiselect>
              </div>
              <div class="relative" :class="{ 'invalid-select': submitted && $v.form.city.$error }" style="width: 100%">
                <multiselect
                  id="city"
                  tag-placeholder="Cidade *"
                  placeholder="Cidade  *"
                  select-label="Selecione uma opção"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  v-model="form.city"
                  label="nome"
                  track-by="nome"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300"
                  :options="cities"
                  @select="getSchools"
                  :multiple="false">
                </multiselect>
              </div>
            </div>

            <div class="block md:flex flex-row gap-2">
              <div class="relative my-4" style="width: 100%">
                <multiselect
                  id="school"
                  tag-placeholder="Escola *"
                  placeholder="Escola  *"
                  select-label="Selecione uma opção"
                  selectedLabel="Selecionado"
                  noOptions="Nenhuma opção disponível"
                  deselectLabel="Desmarcar"
                  v-model="form.school"
                  label="name"
                  track-by="name"
                  @select="setSchool"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200 border-gray-300"
                  :options="schools"
                  :multiple="false">
                </multiselect>
              </div>
            </div>

            <div class="block flex-row gap-2" v-if="form.school">
              <div class="relative" style="width: 100%">
                <h1 class="text-616161 text-sm font-semibold">SGE: {{ form.sge }}</h1>
                <!-- <input type="text" id="sge"
                  v-model="form.sge"
                  disabled
                  class="border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200  bg-gray-100"
                  placeholder="SGE"
                  > -->
              </div>
              <div class="relative" style="width: 100%">
                <h1 class="text-616161 text-sm font-semibold">ID ARCO: {{ form.idarco }}</h1>
              </div>
              <div class="relative" style="width: 100%">
                <h1 class="text-616161 text-sm font-semibold">Convênio: {{ form.convenio }}</h1>
              </div>
            </div>
            <div class="text-xs text-red-600 my-4">*Vagas limitadas: apenas 1 gestor por escola poderá participar.</div>
         
          <div class="next-form py-3 flex gap-2">
            <button
              type="button"
              @click="nextStep()"
              class="flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-303dc6 rounded-lg focus:shadow-outline">
              <span class="flex justify-center mr-2">Prosseguir</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-right">
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
              </svg>
            </button>
          </div>
          </div>
          <div v-if="step === 2">
            <div class="block md:flex flex-row gap-2">
              <div class="relative my-4" style="width: 100%">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 20 20" stroke-linecap="round"
                    stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 text-gray-500">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <input type="text" id="name"
                  v-model="form.name"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="Nome completo *"
                  :class="{ 'border-red-500': submitted && $v.form.name.$error }"
                  >
              </div>
              <div class="relative my-4" style="width: 100%">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 20 20" stroke-linecap="round"
                    stroke-linejoin="round" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 text-gray-500">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                </div>
                <input type="text" id="badge_name"
                  v-model="form.badge_name"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="Nome para Crachá *"
                  :class="{ 'border-red-500': submitted && $v.form.badge_name.$error }"
                  >
              </div>
            </div>
            <div class="block md:flex flex-row gap-2">
              <div class="mb-4 md:mb-0 relative" style="width:100%">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round"
                    stroke-linejoin="round" class="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                </div>
                <input type="text" id="email"
                  v-model="form.email"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="E-mail *"
                  :class="{ 'border-red-500': submitted && $v.form.email.$error }"
                  >
              </div>
              <div class="relative" style="width:100%">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg stroke="currentColor" class="w-5 h-5 text-gray-500" fill="none" stroke-width="2" viewBox="0 0 24 24"
                  stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z">
                  </path>
                </svg>
              </div>
              <input type="text" id="phone"
                v-model="form.phone"
                v-mask="mask"
                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                placeholder="Telefone com DDD *"
                :class="{ 'border-red-500': submitted && $v.form.phone.$error }"
                >
            </div>
            </div>
            <div class="block md:flex flex-row gap-2">
              <div class="relative my-4" style="width:100%">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="none" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H13C16.7712 2 18.6569 2 19.8284 3.17157C21 4.34315 21 6.22876 21 10V14C21 17.7712 21 19.6569 19.8284 20.8284C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8284C3 19.6569 3 17.7712 3 14V10Z" stroke="#1C274C" stroke-width="1.5"/>
                    <path d="M8 10H16" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M8 14H13" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                  </svg>
                </div>
                <input type="text" id="cpf"
                  v-model="form.cpf"
                  v-mask="'###.###.###-##'"
                  @change="validCPF()"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="CPF *"
                  :class="{ 'border-red-500': (submitted && $v.form.cpf.$error)  || errorCPF  }"
                  >
              </div>
              <div class="relative  my-4" style="width:100%">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500" fill="none" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H13C16.7712 2 18.6569 2 19.8284 3.17157C21 4.34315 21 6.22876 21 10V14C21 17.7712 21 19.6569 19.8284 20.8284C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8284C3 19.6569 3 17.7712 3 14V10Z" stroke="#1C274C" stroke-width="1.5"/>
                    <path d="M8 10H16" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                    <path d="M8 14H13" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
                  </svg>
                </div>
                <input type="text" id="rg"
                  v-model="form.rg"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="RG *"
                  :class="{ 'border-red-500': submitted && $v.form.rg.$error }"
                  >
              </div>
            </div>

            <div class="flex flex-row gap-2">
              <div class="relative" style="width: 100%">
                <input type="text" id="birth_date"
                  v-model="form.birth_date"
                  v-mask="maskDate"
                  @blur="() => validateDate18Age()"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="Data de Nascimento *"
                  :class="{ 'border-red-500': (submitted && $v.form.birth_date.$error ) || validateDate.isInvalid }"
                >
                <span v-if="isInvalidDate" class="text-red-500 text-sm">Você deve ter 18 anos ou mais.</span>
              </div>
              <div class="relative" :class="{ 'invalid-select': submitted && $v.form.gender.$error }" style="width: 100%">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"/>
                  </svg>
                </div>
                <multiselect
                  tag-placeholder="Gênero *"
                  placeholder="Gênero  *"
                  v-model="form.gender"
                  select-label="Selecione uma opção"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  label="label"
                  track-by="label"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                  :options="[{label: 'Masculino', value: 'Masculino'}, {label: 'Feminino', value: 'Feminino'}]"
                  :multiple="false">
                </multiselect>
              </div>
            </div>
            <div class="flex flex-row gap-2">
              <div class="relative mt-4" :class="{ 'invalid-select': submitted && $v.form.role.$error }" style="width: 100%">
                  <multiselect
                    tag-placeholder="Cargo *"
                    placeholder="Cargo *"
                    v-model="form.role"
                    select-label="Selecionar"
                    selectedLabel="Selecionado"
                    deselectLabel="Desmarcar"
                    label="label"
                    track-by="value"
                    class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                    :options="roles"
                    :multiple="false">
                </multiselect>
              </div>
            </div>
            <div class="flex flex-row gap-2">
              <div class="relative my-4" :class="{ 'invalid-select': submitted && $v.form.pcd.$error }" style="width: 100%">
                <multiselect
                  tag-placeholder="Necessidades especiais ? *"
                  placeholder="Necessidades especiais?  *"
                  v-model="form.pcd"
                  select-label="Selecione uma opção"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  label="label"
                  track-by="label"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                  :options="pcds"
                  :multiple="false">
                </multiselect>
              </div>
              <div class="relative my-4" :class="{ 'invalid-select': submitted && $v.form.restriction_food.$error }" style="width: 100%">
                <multiselect
                  tag-placeholder="Restrição alimentar ? *"
                  placeholder="Restrição alimentar ?  *"
                  v-model="form.restriction_food"
                  select-label="Selecione uma opção"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  label="label"
                  track-by="label"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                  :options="restriction_foods"
                  :multiple="false">
                </multiselect>
              </div>
            </div>

            <div class="mt-4">
            <h1 class="text-sm font-bold text-gray-600">1. Faça o upload de um documento com foto.</h1>
          </div>
          <div class="py-4 mt-4 bg-white border-dashed border-2 border-gray-300 rounded-lg cursor-pointer" @click="showFile" :class="{ 'invalid-select': submitted && $v.form.documentPhoto.$error }">
            <div class="px-2 py-4 flex justify-center" v-if="!documentPhoto">
              <svg width="48" height="48" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6V13" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 16L10 13L15.5 15.5" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 10C14.8954 10 14 9.10457 14 8C14 6.89543 14.8954 6 16 6C17.1046 6 18 6.89543 18 8C18 9.10457 17.1046 10 16 10Z" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M16 19H19M22 19H19M19 19V16M19 19V22" stroke="currentColor"  stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <p class="text-center text-xs font-medium mt-2 text-green-600" v-if="documentPhoto">{{ documentPhoto.name }}</p>
            <p class="text-center text-xs font-medium mt-2" v-if="!documentPhoto">Clique aqui e anexe o seu documento com foto</p>
            <p class="text-center text-xs font-normal mt-1" v-if="!documentPhoto">PDF, JPG ou PNG</p>
            <input
              ref="aereo"
              type="file"
              class="hidden"
              name="aereo"
              accept="*/*"
              @change="setImage"
            />
          </div>

            <div class="next-form py-3 flex gap-4 mt-10">
            <button
              type="button"
              @click="prevStep()"
              class="flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-gray-600 rounded-lg focus:shadow-outline">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-left">
                <line x1="19" y1="12" x2="5" y2="12" />
                <polyline points="12 19 5 12 12 5" />
              </svg>
              <span class="flex justify-center ml-2">Voltar</span>
            </button>
            <button
              type="button"
              @click="nextStep()"
              class="flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-303dc6 rounded-lg focus:shadow-outline">
              <span class="flex justify-center mr-2">Prosseguir</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-right">
                <line x1="5" y1="12" x2="19" y2="12" />
                <polyline points="12 5 19 12 12 19" />
              </svg>
            </button>
          </div>
            <!-- <div class="flex items-start mb-2 mt-6">
              <div class="flex items-center h-5">
                <input id="authorizes_data_usage" v-model="form.authorizes_data_usage" type="checkbox" key="" class="w-5 h-5 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  required>
              </div>
              <label for="authorizes_data_usage" class="ml-2 text-sm font-medium text-gray-900">Declaro que li e concordo com a <a
                  href="https://www.nuvemshop.com.br/politica-de-privacidade" target="_blank" class="text-blue-600 hover:underline dark:text-blue-500"> Política de privacidade
                  </a></label>
            </div> -->
          </div>
          <div v-if="step === 3">
            <div class="block md:flex flex-row gap-2">
              <div class="relative" :class="{ 'invalid-select': submitted && $v.form.departure_date.$error }" style="width: 100%">
                <multiselect
                  tag-placeholder="Data Ida *"
                  placeholder="Data Ida  *"
                  v-model="form.departure_date"
                  select-label="Selecione"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  label="label"
                  track-by="label"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                  :options="[{label: '26 de março de 2025', value: '26 de março de 2025'}]"
                  :multiple="false">
                </multiselect>
              </div>
              <div class="relative" :class="{ 'invalid-select': submitted && $v.form.return_date.$error }" style="width: 100%">
                <multiselect
                  tag-placeholder="Data Volta *"
                  placeholder="Data Volta  *"
                  v-model="form.return_date"
                  select-label="Selecione"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  label="label"
                  track-by="label"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                  :options="[{label: '28 de março de 2025', value: '28 de março de 2025'}, {label: '29 de março de 2025', value: '29 de março de 2025'}, {label: '30 de março de 2025', value: '30 de março de 2025'}]"
                  :multiple="false">
                </multiselect>
              </div>
            </div>
            <div class="text-xs text-red-600 my-4">*Caso o retorno escolhido seja no sábado ou domingo, os custos extras de hospedagem serão de responsabilidade do participante</div>

            <div class="block md:flex flex-row gap-2">
              <div class="mb-4 md:mb-0 relative" :class="{ 'invalid-select': submitted && $v.form.type_transport.$error }" style="width: 100%">
                <multiselect
                  tag-placeholder="Meio de transporte ? *"
                  placeholder="Meio de transporte?  *"
                  v-model="form.type_transport"
                  select-label="Selecione uma opção"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  label="label"
                  track-by="label"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                  :options="[{label: 'Aéreo', value: 'Aéreo'}, {label: 'Rodoviária', value: 'Rodoviária'}, {label: 'Carro Particular', value: 'Carro Particular'}]"
                  :multiple="false">
                </multiselect>
              </div>
            </div>
            <div class="flex flex-row gap-2">
              <div class="relative my-4" style="width: 100%" v-if="form.type_transport && form.type_transport.label === 'Rodoviária'">
                <input type="text" id="busstation_origin"
                  v-model="form.busstation_origin"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="Rodoviária de origem ?*"
                  :class="{ 'border-red-500': submitted && $v.form.busstation_origin.$error }"
                >
              </div>
              <div class="relative my-4" style="width: 100%" v-if="form.type_transport && form.type_transport.label === 'Aéreo'" :class="{ 'invalid-select': submitted && $v.form.airport_origin.$error }">
                <multiselect
                  tag-placeholder="Aeroporto de Origem ? *"
                  placeholder="Aeroporto de Origem?  *"
                  v-model="form.airport_origin"
                  select-label="Selecionar"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  label="label"
                  track-by="value"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                  :options="airports"
                  :multiple="false">
                </multiselect>
              </div>
            </div>
            <div class="text-xs text-red-600 mb-4" v-if="form.type_transport && form.type_transport.label === 'Aéreo'">*Ao escolher o aeroporto de origem e data de retorno, não serão permitidas alterações futuras.</div>
            <div class="flex flex-row gap-2">
              <div class="relative  my-4" :class="{ 'invalid-select': submitted && $v.form.room_type.$error }" style="width: 100%">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg class="w-5 h-5 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5"/>
                  </svg>
                </div>
                <multiselect
                  tag-placeholder="Tipo de acomodação *"
                  placeholder="Tipo de acomodação  *"
                  v-model="form.room_type"
                  select-label="Selecionar"
                  selectedLabel="Selecionado"
                  deselectLabel="Desmarcar"
                  label="label"
                  track-by="label"
                  class="text-gray-900 text-sm focus:outline-none focus:ring-1 focus:ring-gray-200"
                  :options="[{label: 'Duplo', value: 'double'}, {label: 'Tripla', value: 'triple'}]"
                  :multiple="false">
                </multiselect>
              </div>
              <div class="relative my-4" style="width: 100%">
                <input type="text" id="name_companion"
                  v-model="form.name_companion"
                  class="bg-white border border-gray-300 text-gray-900 text-sm rounded-md block w-full pl-10 p-4 focus:outline-none focus:ring-1 focus:ring-gray-200"
                  placeholder="Nome do acompanhante "
                  >
              </div>
            </div> 
            <div class="text-xs text-red-600 my-2">*O acompanhante também precisa realizar sua inscrição.</div>
            <div class="text-xs text-red-600 my-2">*No momento não há quartos individuais disponíveis no evento. Para dúvidas acione seu consultor pedagógico</div>
            
            <div class="flex items-start mb-4 mt-6">
              <div class="flex items-center h-5">
                <input id="authorizes_data_usage" v-model="form.authorizes_data_usage" type="checkbox" key="" class="w-5 h-5 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  required>
              </div>
              <label for="authorizes_data_usage" class="ml-2 text-sm font-medium text-gray-900">Declaro que li e concordo com a <a
                  href="https://www.sistemapositivo.com.br/politica-de-privacidade/" target="_blank" class="text-blue-600 hover:underline dark:text-blue-500"> Política de privacidade
                  </a></label>
            </div>
            <div class="next-form py-3 flex gap-4">
            <button
              type="button"
              @click="prevStep()"
              class="flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-gray-600 rounded-lg focus:shadow-outline">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-left">
                <line x1="19" y1="12" x2="5" y2="12" />
                <polyline points="12 19 5 12 12 5" />
              </svg>
              <span class="flex justify-center ml-2">Voltar</span>
            </button>
            <button
              type="submit"
              class="flex items-center justify-center w-full h-12 text-white font-semibold text-md uppercase transition-colors duration-150 bg-303dc6 rounded-lg focus:shadow-outline">
              <span class="flex justify-center mr-2">Finalizar Cadastro</span>
            </button>
          </div>
          </div>
        </form>
      </div>
    </section>
    <section class="flex justify-center" v-if="showThanks">
      <div class="w-full sm:w-full lg:w-full xl:w-full pt-10">
        <div class="px-2 pt-2">
          <div class="flex justify-center mb-4">
            <img class="max-h-44 max-w-60 rounded-lg" src="../assets/sucess.png">
          </div>
          <div class="tittle mb-2 px-4 text-center">
            <h1 class="text-3259ce text-md font-bold mb-4">{{ participant.name }},</h1>
            <h1 class="text-616161 text-sm font-semibold">Obrigado pelo seu interesse em construir, junto com a gente, <br />um futuro sustentável e solidário!</h1>
          </div>
          <div class="py-2 mt-12 bg-white border-2 border-gray-200 rounded-lg">
            <div class="mb-2 text-center px-4">
              <h1 class="text-616161 text-sm font-bold mb-4">I M P O R T A N T E</h1>
              <h1 class="text-616161 text-sm font-normal">
                Sua inscrição foi recebida e passará por uma etapa de validação.
                <br />
                <br />
                Assim que concluída, enviaremos a confirmação da sua inscrição e todos os detalhes da sua participação diretamente para o e-mail informado.
                <br />
                <br />
                Portanto, fique atento(a) a sua caixa de entrada.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {VueMaskDirective} from 'v-mask'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import {register} from "@/services/api/register";
import { consultaCities } from "@/services/api/consultaCities";
import { consultaEscolas } from "@/services/api/consultaEscolas";
import {notification} from "@/services/notification";
import { required, email } from 'vuelidate/lib/validators'
import { states } from '@/services/toolbox/states'
import Multiselect from 'vue-multiselect'
import { cpf } from 'cpf-cnpj-validator'

export default {
  name: "form-f1",
  components: { Loading, Multiselect },
  directives: {
    mask: VueMaskDirective
  },
  data: () => ({
    open: false,
    mask: ['(', /\d/, /\d/, ') ', /\d?/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    maskDate: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    showModal: false,
    roles: [
    { label: 'Coordenador Pedagógico', value: 'Coordenador Pedagógico' },
    { label: 'Coordenador Geral (Rede)', value: 'Coordenador Geral (Rede)' },
    { label: 'Coordenador Financeiro', value: 'Coordenador Financeiro' },
    { label: 'Diretor', value: 'Diretor' },
    { label: 'Diretor Geral (Rede)', value: 'Diretor Geral (Rede)' },
    { label: 'Mantenedor', value: 'Mantenedor' },
    { label: 'Gerente Educacional', value: 'Gerente Educacional' },
    { label: 'Vice-Diretor', value: 'Vice-Diretor' },
    { label: 'Presidente', value: 'Presidente' },
    { label: 'Provincial', value: 'Provincial' },
    { label: 'Representante', value: 'Representante' },
    ],
    pcds: [
      { label: 'Não Possuo', value: 'Não Possuo' },
      { label: 'Deficiência visual', value: 'Deficiência visual' },
      { label: 'Deficiência auditiva', value: 'Deficiência auditiva' },
      { label: 'Deficiência física', value: 'Deficiência física' },
      { label: 'Mobilidade reduzida', value: 'Mobilidade reduzida' },
      { label: 'Idoso', value: 'Idoso' },
      { label: 'Gestante', value: 'Gestante' },
      { label: 'Possuo outro tipo de exceção', value: 'Possuo outro tipo de exceção' },
    ],
    restriction_foods: [
      { label: 'Não possuo', value: 'Não possuo' },
      { label: 'Alergia a frutos do mar', value: 'Alergia a frutos do mar' },
      { label: 'Alergia a amendoim', value: 'Alergia a amendoim' },
      { label: 'Alergia a nozes', value: 'Alergia a nozes' },
      { label: 'Alergia a glúten ', value: 'Alergia a glúten ' },
      { label: 'Alergia a ovos', value: 'Alergia a ovos' },
      { label: 'Alergia a soja', value: 'Alergia a soja' },
      { label: 'Intolerância à lactose', value: 'Intolerância à lactose' },
      { label: 'Alergia a trigo', value: 'Alergia a trigo' },
      { label: 'Alergia a peixe', value: 'Alergia a peixe' },
      { label: 'Alergia a milho', value: 'Alergia a milho' },
      { label: 'Vegetariano', value: 'Vegetariano' },
      { label: 'Vegano', value: 'Vegano' },
      { label: 'Possuo outro tipo de restrição alimentar', value: 'Possuo outro tipo de restrição alimentar' },
    ],
    airports: [
      { label: "Aeroporto de Rio Branco/Plácido de Castro (RBR) - Rio Branco", value: "Aeroporto de Rio Branco/Plácido de Castro (RBR) - Rio Branco" },
      { label: "Aeroporto de Maceió/Zumbi dos Palmares (MCZ) - Maceió", value: "Aeroporto de Maceió/Zumbi dos Palmares (MCZ) - Maceió" },
      { label: "Aeroporto de Arapiraca (APQ) - Arapiraca", value: "Aeroporto de Arapiraca (APQ) - Arapiraca" },
      { label: "Aeroporto Internacional de Manaus/Eduardo Gomes (MAO) - Manaus", value: "Aeroporto Internacional de Manaus/Eduardo Gomes (MAO) - Manaus" },
      { label: "Aeroporto de Tefé (TFF) - Tefé", value: "Aeroporto de Tefé (TFF) - Tefé" },
      { label: "Aeroporto de Tabatinga (TBT) - Tabatinga", value: "Aeroporto de Tabatinga (TBT) - Tabatinga" },
      { label: "Aeroporto de Coari (CIZ) - Coari", value: "Aeroporto de Coari (CIZ) - Coari" },
      { label: "Aeroporto de Salvador/Deputado Luís Eduardo Magalhães (SSA) - Salvador", value: "Aeroporto de Salvador/Deputado Luís Eduardo Magalhães (SSA) - Salvador" },
      { label: "Aeroporto de Vitória da Conquista (VDC) - Vitória da Conquista", value: "Aeroporto de Vitória da Conquista (VDC) - Vitória da Conquista" },
      { label: "Aeroporto de Porto Seguro (BPS) - Porto Seguro", value: "Aeroporto de Porto Seguro (BPS) - Porto Seguro" },
      { label: "Aeroporto de Ilhéus/Bahia (IOS) - Ilhéus", value: "Aeroporto de Ilhéus/Bahia (IOS) - Ilhéus" },
      { label: "Aeroporto de Teixeira de Freitas (TXF) - Teixeira de Freitas", value: "Aeroporto de Teixeira de Freitas (TXF) - Teixeira de Freitas" },
      { label: "Aeroporto Internacional de Fortaleza/Pinto Martins (FOR) - Fortaleza", value: "Aeroporto Internacional de Fortaleza/Pinto Martins (FOR) - Fortaleza" },
      { label: "Aeroporto de Juazeiro do Norte (JDO) - Juazeiro do Norte", value: "Aeroporto de Juazeiro do Norte (JDO) - Juazeiro do Norte" },
      { label: "Aeroporto de Jericoacoara (JJD) - Jericoacoara", value: "Aeroporto de Jericoacoara (JJD) - Jericoacoara" },
      { label: "Aeroporto Internacional de Brasília (BSB) - Brasília", value: "Aeroporto Internacional de Brasília (BSB) - Brasília" },
      { label: "Aeroporto de Vitória (VIX) - Vitória", value: "Aeroporto de Vitória (VIX) - Vitória" },
      { label: "Aeroporto de Linhares (LHS) - Linhares", value: "Aeroporto de Linhares (LHS) - Linhares" },
      { label: "Aeroporto de Cachoeiro de Itapemirim (CAJ) - Cachoeiro de Itapemirim", value: "Aeroporto de Cachoeiro de Itapemirim (CAJ) - Cachoeiro de Itapemirim" },
      { label: "Aeroporto de Goiânia/Santa Genoveva (GYN) - Goiânia", value: "Aeroporto de Goiânia/Santa Genoveva (GYN) - Goiânia" },
      { label: "Aeroporto de Rio Verde (RVD) - Rio Verde", value: "Aeroporto de Rio Verde (RVD) - Rio Verde" },
      { label: "Aeroporto de Catalão (CTL) - Catalão", value: "Aeroporto de Catalão (CTL) - Catalão" },
      { label: "Aeroporto de São Luís/Marechal Hugo da Cunha Machado (SLZ) - São Luís", value: "Aeroporto de São Luís/Marechal Hugo da Cunha Machado (SLZ) - São Luís" },
      { label: "Aeroporto de Imperatriz (IMP) - Imperatriz", value: "Aeroporto de Imperatriz (IMP) - Imperatriz" },
      { label: "Aeroporto de Barreirinhas (BHS) - Barreirinhas", value: "Aeroporto de Barreirinhas (BHS) - Barreirinhas" },
      { label: "Aeroporto Internacional de Cuiabá/Marechal Rondon (CGB) - Cuiabá", value: "Aeroporto Internacional de Cuiabá/Marechal Rondon (CGB) - Cuiabá" },
      { label: "Aeroporto de Rondonópolis (ROO) - Rondonópolis", value: "Aeroporto de Rondonópolis (ROO) - Rondonópolis" },
      { label: "Aeroporto de Sinop (OPS) - Sinop", value: "Aeroporto de Sinop (OPS) - Sinop" },
      { label: "Aeroporto de Campo Grande (CGR) - Campo Grande", value: "Aeroporto de Campo Grande (CGR) - Campo Grande" },
      { label: "Aeroporto de Dourados (DOU) - Dourados", value: "Aeroporto de Dourados (DOU) - Dourados" },
      { label: "Aeroporto de Ponta Porã (PMG) - Ponta Porã", value: "Aeroporto de Ponta Porã (PMG) - Ponta Porã" },
      { label: "Aeroporto de Corumbá (CMG) - Corumbá", value: "Aeroporto de Corumbá (CMG) - Corumbá" },
      { label: "Aeroporto de Belo Horizonte/Tancredo Neves (CNF) - Belo Horizonte", value: "Aeroporto de Belo Horizonte/Tancredo Neves (CNF) - Belo Horizonte" },
      { label: "Aeroporto de Uberlândia (UDI) - Uberlândia", value: "Aeroporto de Uberlândia (UDI) - Uberlândia" },
      { label: "Aeroporto de Montes Claros (MOC) - Montes Claros", value: "Aeroporto de Montes Claros (MOC) - Montes Claros" },
      { label: "Aeroporto de Juiz de Fora (JDF) - Juiz de Fora", value: "Aeroporto de Juiz de Fora (JDF) - Juiz de Fora" },
      { label: "Aeroporto de Governador Valadares (GVR) - Governador Valadares", value: "Aeroporto de Governador Valadares (GVR) - Governador Valadares" },
      { label: "Aeroporto Internacional de Belém/Val-de-Cans (BEL) - Belém", value: "Aeroporto Internacional de Belém/Val-de-Cans (BEL) - Belém" },
      { label: "Aeroporto de Marabá (MAB) - Marabá", value: "Aeroporto de Marabá (MAB) - Marabá" },
      { label: "Aeroporto de Santarém (STM) - Santarém", value: "Aeroporto de Santarém (STM) - Santarém" },
      { label: "Aeroporto de Altamira (ATM) - Altamira", value: "Aeroporto de Altamira (ATM) - Altamira" },
      { label: "Aeroporto de Parauapebas (CKS) - Parauapebas", value: "Aeroporto de Parauapebas (CKS) - Parauapebas" },
      { label: "Aeroporto de João Pessoa (JPA) - João Pessoa", value: "Aeroporto de João Pessoa (JPA) - João Pessoa" },
      { label: "Aeroporto de Campina Grande (CPV) - Campina Grande", value: "Aeroporto de Campina Grande (CPV) - Campina Grande" },
      { label: "Aeroporto Internacional de Curitiba/Afonso Pena (CWB) - Curitiba", value: "Aeroporto Internacional de Curitiba/Afonso Pena (CWB) - Curitiba" },
      { label: "Aeroporto de Londrina (LDB) - Londrina", value: "Aeroporto de Londrina (LDB) - Londrina" },
      { label: "Aeroporto de Foz do Iguaçu/Cataratas (IGU) - Foz do Iguaçu", value: "Aeroporto de Foz do Iguaçu/Cataratas (IGU) - Foz do Iguaçu" },
      { label: "Aeroporto de Maringá (MGF) - Maringá", value: "Aeroporto de Maringá (MGF) - Maringá" },
      { label: "Aeroporto de Cascavel (CES) - Cascavel", value: "Aeroporto de Cascavel (CES) - Cascavel" },
      { label: "Aeroporto de Ponta Grossa (PGZ) - Ponta Grossa", value: "Aeroporto de Ponta Grossa (PGZ) - Ponta Grossa" },
      { label: "Aeroporto Internacional do Recife/Guararapes (REC) - Recife", value: "Aeroporto Internacional do Recife/Guararapes (REC) - Recife" },
      { label: "Aeroporto de Caruaru (CAU) - Caruaru", value: "Aeroporto de Caruaru (CAU) - Caruaru" },
      { label: "Aeroporto de Petrolina (PNZ) - Petrolina", value: "Aeroporto de Petrolina (PNZ) - Petrolina" },
      { label: "Aeroporto de Teresina (THE) - Teresina", value: "Aeroporto de Teresina (THE) - Teresina" },
      { label: "Aeroporto de Parnaíba (PHB) - Parnaíba", value: "Aeroporto de Parnaíba (PHB) - Parnaíba" },
      { label: "Aeroporto Internacional do Rio de Janeiro/Galeão (GIG) - Rio de Janeiro", value: "Aeroporto Internacional do Rio de Janeiro/Galeão (GIG) - Rio de Janeiro" },
      { label: "Aeroporto de Santos Dumont (SDU) - Rio de Janeiro", value: "Aeroporto de Santos Dumont (SDU) - Rio de Janeiro" },
      { label: 'Aeroporto de Campos dos Goytacazes, RJ', value: 'Aeroporto de Campos dos Goytacazes, RJ' },
      { label: "Aeroporto Internacional de Natal (NAT) - Natal", value: "Aeroporto Internacional de Natal (NAT) - Natal" },
      { label: "Aeroporto de Mossoró (MVF) - Mossoró", value: "Aeroporto de Mossoró (MVF) - Mossoró" },
      { label: "Aeroporto Internacional de Porto Alegre/Salgado Filho (POA) - Porto Alegre", value: "Aeroporto Internacional de Porto Alegre/Salgado Filho (POA) - Porto Alegre" },
      { label: "Aeroporto de Pelotas (PET) - Pelotas", value: "Aeroporto de Pelotas (PET) - Pelotas" },
      { label: "Aeroporto de Passo Fundo (PFB) - Passo Fundo", value: "Aeroporto de Passo Fundo (PFB) - Passo Fundo" },
      { label: "Aeroporto de Caxias do Sul (CXJ) - Caxias do Sul", value: "Aeroporto de Caxias do Sul (CXJ) - Caxias do Sul" },
      { label: "Aeroporto de Porto Velho (PVH) - Porto Velho", value: "Aeroporto de Porto Velho (PVH) - Porto Velho" },
      { label: "Aeroporto de Ji-Paraná (JPR) - Ji-Paraná", value: "Aeroporto de Ji-Paraná (JPR) - Ji-Paraná" },
      { label: "Aeroporto de Boa Vista (BVB) - Boa Vista", value: "Aeroporto de Boa Vista (BVB) - Boa Vista" },
      { label: "Aeroporto Internacional de Florianópolis/Hercílio Luz (FLN) - Florianópolis", value: "Aeroporto Internacional de Florianópolis/Hercílio Luz (FLN) - Florianópolis" },
      { label: "Aeroporto de Joinville-Lauro Carneiro de Loyola (JOI) - Joinville", value: "Aeroporto de Joinville-Lauro Carneiro de Loyola (JOI) - Joinville" },
      { label: "Aeroporto de Navegantes (NVT) - Navegantes", value: "Aeroporto de Navegantes (NVT) - Navegantes" },
      { label: "Aeroporto de Chapecó (XAP) - Chapecó", value: "Aeroporto de Chapecó (XAP) - Chapecó" },
      { label: "Aeroporto Internacional de São Paulo/Guarulhos (GRU) - São Paulo", value: "Aeroporto Internacional de São Paulo/Guarulhos (GRU) - São Paulo" },
      { label: "Aeroporto de Campinas/Viracopos (VCP) - Campinas", value: "Aeroporto de Campinas/Viracopos (VCP) - Campinas" },
      { label: 'Aeroporto Presidente Prudente, SP', value: 'Aeroporto Presidente Prudente, SP' },
      { label: "Aeroporto de São José do Rio Preto (SJP) - São José do Rio Preto", value: "Aeroporto de São José do Rio Preto (SJP) - São José do Rio Preto" },
      { label: "Aeroporto de Congonhas (CNH) - Sao Paulo", value: "Aeroporto de Congonhas (CNH) - Sao Paulo" }, 
      { label: "Aeroporto de Aracaju/Santa Maria (AJU) - Aracaju", value: "Aeroporto de Aracaju/Santa Maria (AJU) - Aracaju" },
      { label: "Aeroporto de Palmas (PMW) - Palmas", value: "	Aeroporto de Palmas (PMW) - Palmas" },
      { label: "Aeroporto de Araguaína (AUX) - Araguaína", value: "Aeroporto de Araguaína (AUX) - Araguaína" },
      { label: "Aeroporto Internacional de Cruzeiro do Sul (CZS) -Cruzeiro do Sul, AC", value: "Aeroporto Internacional de Cruzeiro do Sul (CZS) - Cruzeiro do Sul, AC" },
     
    ],
    documentPhoto: null,
    states: states,
    cities: [],
    schools: [],
    loading: true,
    submitted: false,
    showForm: true,
    step: 1,
    showThanks: false,
    errorCPF: false,
    participant: {},
    isInvalidDate: false,
    validateDate: {
      inputValue: null,
      isInvalid: false,
      hasTouched: false,
    },
    form: {
      sge: null,
      convenio: null,
      school: null,
      name: null,
      birth_date: null,
      phone: null,
      badge_name: null,
      email: null,
      cpf: null,
      rg: null,
      gender: null,
      pcd: null,
      restriction_food: null,
      documentPhoto: null,
      role: null,
      departure_date: null,
      return_date: null,
      type_transport: null,
      airport_origin: null,
      busstation_origin: null,
      state: null,
      city: null,
      room_type: null,
      name_companion: null,
      authorizes_data_usage: null
    },
    formIsDisabled: false,
  }),
  validations () {

    let step1 = {
      city: { required },
      state: { required },
      school: { required },
    }

    let step2 = {
      name: { required },
      badge_name: { required },
      birth_date: { required },
      email: { required, email },
      phone: { required },
      role: { required },
      gender: { required },
      cpf: { required },
      rg: { required },
      documentPhoto: { required },
      pcd: { required },
      restriction_food: { required },
    }

    let step3 = {
      return_date: { required },
      departure_date: { required },
      type_transport: { required },
      room_type: { required }
    }

    if (this.form.type_transport && this.form.type_transport.value === 'Aéreo') {
      step3 = {
        ...step3,
        airport_origin: { required },
      }
    }

    if (this.form.type_transport && this.form.type_transport.value === 'Rodoviária') {
      step3 = {
        ...step3,
        busstation_origin: { required },
      }
    }

    let valid = step1
    if (this.step === 2) {
      valid = {
        ...valid,
        ...step2,
      }
    }

    if (this.step === 3) {
      valid = {
        ...valid,
        ...step3,
      }
    }
    return { form: valid }
  },
  destroyed () {
    document.body.style.overflow = "visible";
  },
  mounted () {
    document.body.style.overflow = "hidden";
    this.loading = false;
  },
  methods: {
    showFile () {
      this.$refs.aereo.click()
    },
    validateDate18Age () {
      const birthDate = this.form.birth_date;
      if (!birthDate || !this.isValidDate(birthDate)) {
        this.isInvalidDate = true;
        return;
      }
      this.isInvalidDate = false;
    },
    isValidDate(dateStr) {
      // Verifica se a data está no formato DD/MM/YYYY e se é uma data válida
      const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
      if (!dateStr.match(regex)) return false;

      const [day, month, year] = dateStr.split('/').map(Number);
      const date = new Date(year, month - 1, day);
      return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year;
    },
    setImage(e) {
      const file = e.target.files[0];
      const allowedExtensions = ['pdf', 'png', 'jpg', 'jpeg']; // Lista de extensões permitidas
      // Verificar se o tipo do arquivo é uma imagem ou se a extensão está na lista permitida
      if (!allowedExtensions.includes(file.name.split('.').pop().toLowerCase())) {
        // errorMessage('Alerta: Por favor selecione uma imagem, PDF, PNG ou JPG.');
        notification.error('Atenção: adicione apenas arquivos do tipo: PDF,JPG ou PNG');
        return;
      }

      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        notification.error('Atenção: Tamanho máximo 5MB');
        return;
      }

      if (typeof FileReader === 'function') {
        this.documentPhoto = file;
        this.form.documentPhoto = file;
      } else {
        notification.error('Atenção: Navegador não suporta filereader');
      }
    },
    validCPF () {
      const cpfValid = cpf.isValid(this.form.cpf)
      if (!cpfValid) {
        this.errorCPF = true
        return
      }
      this.errorCPF = false
    },
    prevStep () {
      this.step -= 1;
    },
    nextStep () {
      this.$v.form.$touch()
      this.submitted = true
      if (this.$v.form.$invalid) {
        this.loading = false;
        return
      }
      // const textHeader = ['','INSCRIÇÃO - DADOS PESSOAIS', 'INSCRIÇÃO - DADOS DO EVENTO', 'INSCRIÇÃO - ENVIO DE DOCUMENTOS'][this.step - 1]
      // this.$emit('headerDrawer', 'INSCRIÇÃO - ENVIO DE DOCUMENTOS');
      this.step += 1;
      this.submitted = false
    },
    setState (option) {
      if (option.value) {
        this.getCities(option.value)
        return
      }
    },
    async getCities (uf) {
      this.cities = [];
      const respCities = await consultaCities(uf);
      if (!respCities) return
      this.cities = respCities.cities
    },
    async getSchools () {
      this.schools = [];
      const respSchools = await consultaEscolas({ state: this.form.state.value, city: this.form.city.nome });
      if (!respSchools) return
      this.schools = respSchools.schools
    },
    setSchool (option) {
      if (option) {
        this.form.sge = option.sge;
        this.form.convenio = option.convenio;
        this.form.idarco = option.idarco;
        return;
      }
      this.form.sge = null;
      this.form.convenio = null;
      this.form.idarco = null;
    },
    toggle() {
      this.openRightDrawer = !this.openRightDrawer;
    },
    async submitRegister () {
      try {
        this.loading = true;
        let resp;
        this.submitted = true
        this.loading = true;
        this.$v.form.$touch()
        console.log('cheguei aqui')
        console.log('form', this.form)
        console.log('validation', this.$v.form)

        if (this.$v.form.$invalid) {
          this.loading = false;
          return
        }
        console.log(this.form)
        // this.form.documentPhoto = this.documentPhoto;
        // throw new Error('Interrompendo o script aqui');
        // eslint-disable-next-line no-unreachable
        resp = await register(this.form);
        this.participant = resp;
        this.showForm = false;
        this.showThanks = true;
        this.loading = false;
        this.$v.$reset();
      } catch (err) {
        notification.error(err?.errMsg ?? 'Houve um problema para concluir inscrição');
        console.log(err)
        this.loading = false;
        return;
      }
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__select {
  height: 50px !important;
}
.multiselect__tags {
  padding: 16px 40px 0 16px !important;
  min-height: 54px !important;
}
.i-file {
  margin-bottom: 1rem;
  max-width: 520px;
}

.c-s {
  margin-top: 2.5rem;
}
.bg-inactive {
  background: #ededed;
}
.invalid-select {
  border: 1px solid #ee4648 !important;
  border-radius: 4px;
}
</style>
